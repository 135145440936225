<template>
  <div class="combo" ref="combo">
    <h2>查询条件</h2>
    <div class="box">
      <div class="select-box">
        套餐名称
        <a-input v-model:value="search.productName" class="mt5" placeholder="输入套餐名称" />
      </div>
      <div class="select-box" style="width: 260px">
        泛员价格
        <div style="display: flex; justify-content: space-between; align-items: center">
          <a-input
            prefix="￥"
            v-model:value="search.lowFYUPrice"
            style="width: 120px"
            class="mt5"
          />
          -
          <a-input
            prefix="￥"
            v-model:value="search.highFYUPrice"
            style="width: 120px"
            class="mt5"
          />
        </div>
      </div>
      <div class="select-box">
        是否上架
        <a-select v-model:value="search.isValid" class="mt5" style="width: 200px">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="false">否</a-select-option>
          <a-select-option value="true">是</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="button-box">
      <a-button :loading="getLoading" style="width: 85px" type="primary" @click="getListData"
        >查询
      </a-button>
      <a-button style="width: 85px" type="primary" @click="reset">重置 </a-button>
      <a-button style="width: 85px" type="primary" @click="publicRemark">备注 </a-button>
      <a-button style="width: 115px" type="primary" @click="$router.push('/comboType')"
        >套餐类型管理
      </a-button>
      <a-button
        type="primary"
        style="width: 115px"
        @click="$router.push({ path: '/combo/detail', query: { action: 'add' } })"
        >新增体检套餐
      </a-button>
    </div>

    <a-table
      @change="tableChange"
      bordered
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :pagination="position"
      class="mt30 table-style"
      :row-class-name="(_record, index) => (index !== 1 ? 'table-content' : null)"
    >
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.dataIndex === 'isRecommend'">
          {{ text ? '是' : '否' }}
        </template>
        <template v-if="column.dataIndex === 'isHot'">
          {{ text ? '是' : '否' }}
        </template>
        <template v-if="column.dataIndex === 'isValid'">
          {{ text ? '是' : '否' }}
        </template>
        <template v-if="column.dataIndex === 'action'">
          <div class="action-box">
            <a-button @click="gotoDetail('check', record.guid)">查看</a-button>
            <a-button @click="gotoDetail('edit', record.guid)">编辑</a-button>
            <a-button
              @click="
                $router.push({
                  path: '/configure',
                  query: { guid: record.guid }
                })
              "
              type="primary"
              >配置产品
            </a-button>
            <a-button
              type="primary"
              @click="
                $router.push({
                  path: '/comboProduct',
                  query: { guid: record.guid }
                })
              "
              >体检项目
            </a-button>
          </div>
        </template>
      </template>
    </a-table>

    <a-modal
      width="60%"
      :getContainer="$refs.combo"
      v-model:visible="visible"
      :maskClosable="false"
      title="产品公共注意事项"
      :confirm-loading="confirmLoading"
      ok-Text="保存"
      @ok="handleOk"
      centered
    >
      <a-divider orientation="left" style="font-size: 20px">公共注意事项编辑 </a-divider>
      <a-textarea v-model:value="remark" :auto-size="{ minRows: 2, maxRows: 20 }" />
    </a-modal>
  </div>
</template>

<script setup>
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode, reactive, ref } from 'vue';
import { comboData, publicRemarkData, remarkEdit } from '@/api';
import { message, Modal } from 'ant-design-vue';
import { useRouter } from 'vue-router';

const router = useRouter();
//表格数据loading
const loading = ref(false);
//表格数据
const dataSource = ref([]);
//表格配置
const columns = [
  {
    title: '套餐编号',
    dataIndex: 'fyuCode',
    key: 'fyuCode',
    align: 'center'
  },
  {
    title: '套餐名称',
    dataIndex: 'productName',
    key: 'productName',
    align: 'center'
  },
  {
    title: '市场价',
    key: 'retailPrice',
    dataIndex: 'retailPrice',
    align: 'center'
  },
  {
    title: '泛员价',
    key: 'fyuPrice',
    dataIndex: 'fyuPrice',
    align: 'center'
  },
  {
    title: '推荐',
    key: 'isRecommend',
    dataIndex: 'isRecommend',
    align: 'center'
  },
  {
    title: '优选',
    key: 'isHot',
    dataIndex: 'isHot',
    align: 'center'
  },
  {
    title: '是否上架',
    key: 'isValid',
    dataIndex: 'isValid',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 350
  }
];

//分页配置
let position = reactive({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  pageSizeOptions: ['10', '20', '30', '100'],
  total: 0,
  showTotal: total => `共${total}条`,
  position: ['bottomLeft']
});
//分页事件
const tableChange = pagination => {
  getInquire(pagination);
};
//查询
const formSource = () => {
  return reactive({
    pageIndex: 1,
    productName: '',
    lowFYUPrice: '',
    highFYUPrice: '',
    isValid: 'true'
  });
};
let search = formSource();
let conditionData = formSource();
const getInquire = pagination => {
  loading.value = true;
  if (pagination) {
    conditionData.pageIndex = pagination.current;
    conditionData.pageSize = pagination.pageSize;
  } else {
    search.pageIndex = 1;
  }
  comboData(conditionData)
    .then(res => {
      const data = res.data;
      dataSource.value = data.data;
      position.total = data.count;
      loading.value = false;
      if (pagination) {
        position.current = pagination.current;
        position.pageSize = pagination.pageSize;
      } else {
        position.current = 1;
      }
    })
    .catch(error => {
      loading.value = false;
      Modal.error({
        content: error.response.data
      });
    });
};
let getLoading = ref(false);
const getListData = () => {
  // console.log(searchCity.value);
  getLoading.value = true;
  conditionData = Object.assign(conditionData, search);
  conditionData.pageSize = position.pageSize;
  getInquire();
  getLoading.value = false;
};
getListData();
//重置
const reset = () => {
  search = Object.assign(search, formSource());
};
const gotoDetail = (action, id) => {
  router.push({ path: '/combo/detail', query: { action: action, guid: id } });
};

//备注
const visible = ref(false);
const confirmLoading = ref(false);
const remark = ref('');
const publicRemark = () => {
  publicRemarkData()
    .then(res => {
      remark.value = res.data;
      visible.value = true;
    })
    .catch(error => {
      Modal.error({
        content: error.response.data
      });
    });
};
//编辑
const handleOk = () => {
  Modal.confirm({
    title: '确认保存修改信息?',
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    onOk() {
      confirmLoading.value = true;
      remarkEdit(remark.value)
        .then(() => {
          confirmLoading.value = false;
          visible.value = false;
          message.success('保存成功');
        })
        .catch(error => {
          Modal.error({
            content: error.response.data
          });
        });
    },
    onCancel() {}
  });
};
</script>

<style scoped>
.box {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.select-box {
  width: 200px;
  margin-right: 40px;
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt30 {
  margin-top: 30px;
}

.button-box {
  width: 540px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.action-box {
  display: flex;
  justify-content: space-between;
}

:deep(.ant-table-tbody) .ant-table-cell {
  padding: 4px 16px !important;
}
</style>
