import axios from 'axios'

// 创建 axios 实例
let instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 1000 * 30
})

instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
instance.defaults.headers['Cache-Control'] = 'no-cache'
instance.defaults.withCredentials = true

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    if (config.method === 'get') {
      // 给GET 请求后追加时间戳
      let symbol = config.url.indexOf('?') >= 0 ? '&' : '?'
      config.url += symbol + '_=' + Date.now()
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
