import http from '../http/axios'

//后台获取泛优产品列表
export function pageProductFY(data) {
  return http({
    url: '/api/ProductFY/admin/page_productFY',
    method: 'get',
    params: data
  })
}

//后台获取泛优产品详情
export function productFYDetail(data) {
  return http({
    url: `/api/ProductFY/${data}`,
    method: 'get'
  })
}

//获取套餐详情
export function productDetail(data) {
  return http({
    url: `/product/${data}`,
    method: 'get'
  })
}

//获取所有套餐类型
export function productTypeAll() {
  return http({
    url: '/productType/all',
    method: 'get'
  })
}

//同步泛优产品信息， 并且保存产品基本信息，产品详情和体检机构信息进数据库
export function syncData() {
  return http({
    url: '/api/ProductFY/sync',
    method: 'post'
  })
}

//体检套餐数据
export function comboData(data) {
  return http({
    url: '/product/admin/page',
    method: 'get',
    params: data
  })
}

//体检套餐添加或编辑
export function productAddOrEdit(data) {
  return http({
    url: '/product/edit',
    method: 'post',
    data: data
  })
}

//获取产品公共注意事项
export function publicRemarkData() {
  return http({
    url: '/product/publicRemark',
    method: 'get'
  })
}

//产品公共注意事项编辑
export function remarkEdit(data) {
  return http({
    url: '/product/publicRemark/edit',
    method: 'post',
    data: `remark=${data}`
  })
}

//体检套餐检查项目列表
export function productDetailList(guid) {
  return http({
    url: `/api/ProductDetail/admin/list/${guid}`,
    method: 'get'
  })
}

//按钮获取体检项目对应的API,初始化或覆盖套餐的体检项目
export function productDetailInit(guid) {
  return http({
    url: `/api/ProductDetail/init/${guid}`,
    method: 'post'
  })
}

//批量修改体检检测信息
export function productDetailEdit(guid, data) {
  return http({
    url: `/api/ProductDetail/${guid}`,
    method: 'put',
    data: data
  })
}

//解除泛优产品与套餐绑定关系
export function productUnbinds(guid, data) {
  return http({
    url: `/product/unbinds/${guid}`,
    method: 'post',
    data: data
  })
}

//添加泛优产品与套餐绑定关系
export function productBinds(guid, data) {
  return http({
    url: `/product/binds/${guid}`,
    method: 'post',
    data: data
  })
}

//套餐类别
export function productType(data) {
  return http({
    url: '/productType',
    method: 'get',
    params: data
  })
}

export function productTypeDetails(data) {
  return http({
    url: `/productType/${data}`,
    method: 'get'
  })
}

export function productTypeAdd(data) {
  return http({
    url: '/productType/add',
    method: 'post',
    headers: {
      'Content-Type': `multipart/form-data;`
    },
    data: data
  })
}

export function productTypeEdit(id, data) {
  return http({
    url: `/productType/edit/${id}`,
    method: 'post',
    headers: {
      'Content-Type': `multipart/form-data;`
    },
    data: data
  })
}

export function productTypeDel(id) {
  return http({
    url: `/productType/${id}`,
    method: 'delete'
  })
}

//获取客户列表
export function getCustomerData() {
  return http({
    url: `/Customer`,
    method: 'get'
  })
}

// 后台管理 查询所有的体检预约单
export function getAnnualData(data) {
  return http({
    url: `/order/admin/page`,
    method: 'get',
    params: data
  })
}

//取员工订单状态列表
export function getOrderStatus() {
  return http({
    url: '/order/orderStatus',
    method: 'get'
  })
}

//根据企业ID获取产品名称
export function getProducts(data) {
  const customerId = { customerId: data }
  return http({
    url: '/ContractOrder/Products',
    method: 'get',
    params: customerId
  })
}

//管理员查看体检详情, 包括订单的预约历史记录
export function getOrderDetail(orderId) {
  return http({
    url: `/order/OrderDetail/${orderId}`,
    method: 'get'
  })
}
